import React,{useState,useEffect} from 'react';
import  PageContainer  from '.././components/PageContainer';
import { TextField,Switch,FormControl} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Window,InfoHover } from '../components';
import {loginRequest} from '../redux/actions/authActions';
import { connect } from "react-redux";
import {useNavigate } from "react-router-dom";
function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

const Login = ({loginReq,loader,isAuth}) => {
    const [isEmail,setIsEmail]=useState(false);
    const [userIdentifier,setUserIdentifier]=useState('');
    const [password,setPassword]=useState('');
    const navigate = useNavigate(); 
    const handleLogin=async()=>{
        try{
            const userIdentifierType=isEmail ? 'email' : 'username';
            const credentialData={
                userIdentifier,
                password:password
            };
            const loginDataObj={
                userIdentifierType,
                credentialData
            }
            loginReq(loginDataObj);
        }
        catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
        isAuth && navigate('/');
      },[isAuth])
    return (
        <PageContainer classes={'h-vfull'} subHeader='Login Page'>

            <form style={{margin:'auto'}}>
                <Window header={'Enter Credentials'} childContainerClasses={'bg-white'}>
                    <div className='login_credentials-container'>
                        <FormControl className='item1'>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                label={isEmail ? `Email` : `Username`}
                                type={isEmail ? `email` : `text`}
                                value={userIdentifier}
                                onChange={(e)=>setUserIdentifier(e.target.value)}
                            />
                            <div className='isEmail-container'>
                                <InfoHover info={'Login By Email or Username'}>
                                    <Switch className='rotate' checked={isEmail} color="warning" onChange={()=>setIsEmail(!isEmail)}/>
                                </InfoHover>
                            </div>
                        </FormControl>
                        <FormControl className='item2'>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                name="password"
                                label="password"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </FormControl>
                        <FormControl className='item3'>
                                <LoadingButton loading={loader} type='submit' variant='contained' onClick={handleLogin}>Login</LoadingButton>
                        </FormControl>
                    </div>
                </Window>
            </form>
            
            
        </PageContainer>
    );
};
const mapStateToProps = (state) => {
    return {
      loader: state.authUser.loader,
      isAuth:state.authUser.isAuth,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      loginReq: (credentials) => dispatch(loginRequest(credentials)),
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(Login);