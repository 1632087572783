import Button from '@mui/material/Button';
import ArticleIcon from '@mui/icons-material/Article';
import api from "../api";
import { useState } from 'react';
const openDoc=async(dbtype,docPath,setErrorState)=>{
    try{
        if(dbtype!=='businessdoc' && dbtype!=='pattern'){
            setErrorState('dbtype Issue');
            console.log(dbtype);
            return;
        }
        const response = await api.getDoc(dbtype,docPath);
        if (!response.ok) {
            setErrorState('HTTP Error! ');
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const blobURL = window.URL.createObjectURL(blob);

        window.open(blobURL);
        // Cleanup after use (optional, to free memory later)
        setTimeout(() => {
            window.URL.revokeObjectURL(blobURL);
        }, 10000); 
    }
    catch(err){
        console.log(err);
    }
}
const ViewDoc = ({dbtype,docPath}) => {

    const [isError,setIsError]=useState(false)
    const onViewDoc=async (e)=>{
        try{
            openDoc(dbtype,docPath,setIsError);
        }
        catch(err){
            console.log(err);
        }
    }
    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<ArticleIcon />}
            style={{backgroundColor:'white'}}
            onClick={onViewDoc}
        >
            {isError ? isError : 'View Doc'}
        </Button>
    );
};

export default ViewDoc;