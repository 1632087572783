import {PageContainer,Add,Form,BusinessDocContainer} from '../components';
import { connect } from "react-redux";
import { useState } from 'react';

const BussinessExp = ({businessDocs}) => {
    const [formParams,setFormParams]=useState(false);
    return (
        <PageContainer header={'Our Business Plan Explained In Documents'}>
            <BusinessDocContainer businessDocs={businessDocs}/>
             <Add route='businessdoc' setFormMethod={setFormParams}/>
             {formParams!==false?  <Form dbtype={formParams[0]} method={formParams[1]} setFormMethod={setFormParams}/> : <></>}
        </PageContainer>
    );
};
const mapStateToProps = (state) => {
    return {
        businessDocs: state.userInfo.businessdoc,
      };
  };
export default connect(mapStateToProps)(BussinessExp);