import {
    LOGOUT_SUCCESS,RESET_IS_ERROR,SET_BY_KEY,
    FETCH_DATA_REQUEST,FETCH_DATA_SUCCESS,FETCH_DATA_FAILURE,
    FETCH_USER_INFO,FETCH_USER_INFO_SUCCESS,FETCH_USER_INFO_FAILURE,
    SET_DATA_REQUEST,SET_DATA_SUCCESS,SET_DATA_FAILURE,SET_BY_KEY_AND_ID,
} from "../types/userInfoTypes";

const initializeUserInfoState={
        pattern:[],
        department:[],
        businessdoc:[],
        isError:{
            status:null , 
            message:'' , 
            messageType:''
        },
        
}


export default function reducer(state=initializeUserInfoState,action){
    switch (action.type){

        case FETCH_DATA_REQUEST:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_DATA_SUCCESS:
            return{...state,[action.dbtype]: action.payload,loader:false,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_DATA_FAILURE:
            return{...state,isError:{...state.isError,status:true},loader:false};

        case FETCH_USER_INFO:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case FETCH_USER_INFO_SUCCESS:
            return {...state,...action.payload,loader:false,isError:{status:null ,message:'' ,messageType:'' }}
        case FETCH_USER_INFO_FAILURE:
            return{...state,isError:{...state.isError,status:true},loader:false};




        // HANDLE API REQ ON ANYTHING RELATED TO USER DATA
        case SET_DATA_REQUEST:
            return {...state,loader:true,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_DATA_SUCCESS:
            if(!action.hasOwnProperty('method')){
                return {...state,loader:false,isError:{...state.isError,status:false}};
            }
            if(action.method==='POST'){
                return {...state,[action.dbtype]:[...state[action.dbtype],action.payload],loader:false,isError:{...state.isError,status:false}}
            }
            if(action.method==='DELETE'){
                const indexOfItemToDelete = state[action.dbtype].findIndex(item => item._id == action.payload._id);
                if (indexOfItemToDelete !== -1) {
                    return {
                      ...state,
                      [action.dbtype]: state[action.dbtype].filter((item, index) => index !== indexOfItemToDelete),
                      loader:false,
                      isError:{...state.isError,status:false}
                    };
                }    
                
               
            }
            if(action.method==='PUT'){
                const indexOfItemToUpdate = state[action.dbtype].findIndex(item => item._id == action.payload._id);
                if (indexOfItemToUpdate !== -1) {
                    return {
                      ...state,
                      [action.dbtype]: state[action.dbtype].map((item, index) =>
                        index === indexOfItemToUpdate ? { ...item, ...action.payload } : item
                      ),
                      loader:false,
                      isError:{...state.isError,status:false}
                    }
                }
            }
            return {...state,loader:false,isError:{...state.isError,status:true}};
       
        case SET_DATA_FAILURE:
            return {...state,loader:false,isError:{...state.isError,status:true}};
        case RESET_IS_ERROR:
            return {...state,isError:{status:null ,message:'' ,messageType:'' }};
        case SET_BY_KEY:
            return {...state,[action.key]:{...state[action.key],...action.payload}}
        case SET_BY_KEY_AND_ID:
            let newStateKey=state[action.key].map((keyValue)=> (keyValue['_id']==action['_id']) ? {...keyValue,...action.payload} : keyValue);
            return {...state,[action.key]:newStateKey};


     
    
        case LOGOUT_SUCCESS:
            return {
                pattern:[],
                department:[],
                businessdoc:[],
                isError:{
                    status:null , 
                    message:'' , 
                    messageType:''
                },
            }
        default :
            return state
    }
}


