import dataAdapter from "../dataAdapter";


const api={
    newData: (dbtype, data) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/new-${dbtype}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
    },
    addData: (dbtype, data) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/add`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    },
    get:(dbtype,path)=>{
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${path}`);
    },
    update: (dbtype, id, data) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    },
    getOne: (dbtype, id) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
        method: 'GET',
    });
    },
    getAll: (dbtype) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/getAll`, {
        method: 'GET',
        });
    },
    getAllByFactor:(dbtype,Factor)=>{
    return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/getAll/${Factor}`, {
        method: 'GET',
    });
    },
    delete: (dbtype, id) => {
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${id}`, {
        method: 'DELETE',
    });
    },
    post:(dbtype,path,data)=>{
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/${path}`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    },
    postFile:async (dbtype,formData)=>{
        try {
            return await fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/new-${dbtype}`, {
              method: 'POST',
              body: formData,
            });
      
          } catch (error) {
            console.error('Error posting FormData:', error);
            throw error;
          }
    },
    postPath:(path,data)=>{
        return fetch(`${process.env.REACT_APP_BACKEND_API}/${path}`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    },
    getUserInfo:()=>{
        return fetch(`${process.env.REACT_APP_BACKEND_API}/user/getRequired`);
    },
    logout:()=>{
        return fetch(`${process.env.REACT_APP_BACKEND_API}/auth/logout`);
    },
    setData:function(dbtype,method,data){
        switch (method) {
            case 'POST':
            data.hasOwnProperty('_id') && delete data['_id'];
            return this.newData(dbtype,data);
            case 'PUT':
            data.hasOwnProperty('notifications') && delete data['notifications'];
            return this.update(dbtype,data._id,data);
            case 'DELETE':
            return this.delete(dbtype,data._id);
            default:
            return false;
        }
    },
    isData_ObjValid:(dbtype,method,data)=>{
    const validObjKeys=Object.keys(dataAdapter.formKeys(dbtype));
    const unneededKeys = validObjKeys.filter(key => !validObjKeys.includes(key));
    for(const unneededKey of unneededKeys){
        data.hasOwnProperty(unneededKey) && delete data[unneededKey];
    }
    },
    getPatternDoc:(patternDoc)=>{
       return fetch(`${process.env.REACT_APP_BACKEND_API}/pattern/doc/${patternDoc}`)
    },
    getDoc:(dbtype,docPath)=>{
       return fetch(`${process.env.REACT_APP_BACKEND_API}/${dbtype}/doc/${docPath}`)
    },
}
api.setData = api.setData.bind(api);
export default api;