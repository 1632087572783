
import { all } from 'redux-saga/effects';
import authSaga from "./authSaga";
import userInfoSaga from "./userInfoSaga";





export default function* rootSaga(){
    yield all([authSaga(),userInfoSaga()])
}