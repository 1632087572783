// SAGA's
    // responsible for  userInfo data ->backend /user/getRequired
    export const FETCH_USER_INFO='FETCH_USER_INFO';
    export const FETCH_USER_INFO_SUCCESS='FETCH_USER_INFO_SUCCESS';
    export const FETCH_USER_INFO_FAILURE='FETCH_USER_INFO_FAILURE';

    // responsible for post put delete requests
    export const SET_DATA_REQUEST='SET_DATA_REQUEST';
    export const SET_DATA_SUCCESS='SET_DATA_SUCCESS';
    export const SET_DATA_FAILURE='SET_DATA_FAILURE';


    // responsible for fetching any data from api - used on cases that might require updates
    export const FETCH_DATA_REQUEST='FETCH_DATA_REQUEST';
    export const FETCH_DATA_SUCCESS='FETCH_DATA_SUCCESS';
    export const FETCH_DATA_FAILURE='FETCH_DATA_FAILURE';

// responsible to reset isError to null after requests
export const RESET_IS_ERROR='RESET_IS_ERROR';
export const SET_BY_KEY='SET_BY_KEY';
export const SET_BY_KEY_AND_ID='SET_BY_KEY_AND_ID';


export const LOGOUT_SUCCESS='LOGOUT_SUCCESS';