import React from 'react';
import Form from '../Form';

const AddDepartment = ({classes,style}) => {
    return (
        <div style={style ? style : {}} className={`${classes}`}>
            <Form dbtype={'department'} method={'POST'} setFormMethod={true}/>
        </div>
    );
};

export default AddDepartment;
