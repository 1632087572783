import React from 'react';
import  PageContainer  from '.././components/PageContainer';  
import AddUser from '../components/admin/AddUser';
import AddDepartment from '../components/admin/AddDepartment';
import WaitingList from '../components/admin/WaitingList';
const AdminPage = () => {
    return (
        <PageContainer header='Admin Page'>
            <div className='admin_input-container'>
                <AddUser style={{width:'400px'}}/>
                <AddDepartment style={{width:'400px'}}/>
            </div>

        </PageContainer>
    );
};

export default AdminPage;