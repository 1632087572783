import React from 'react';
import './window.css';
const Window = ({children,header,classes,childContainerClasses}) => {
    return (
        <div className={`window-container ${classes && classes}`}>
            <header><h3>{header}</h3></header>
            <div className={`window_content-container ${childContainerClasses && childContainerClasses}`}>
                {children}
            </div>
        </div>
    );
};

export default Window;