import React,{useState} from 'react';
import {PageContainer,Add,Form,PageSlider,PatternsViewer} from '../components';

const getPatternSubPages=()=>{
    const patternsSubPages=[

      {
        name:'Pending Statistics',
        heading:'Patterns Pending For Statistics',
        component:()=><PatternsViewer type={-1}/>,
      },
      {
        name:'Got Statistics',
        heading:'Patterns Pending For Approval',
        component:()=><PatternsViewer type={0}/>,
      },
      {
        name:'Pending Monitoring',
        heading:'Approved Patterns Pending Monitoring',
        component:()=><PatternsViewer type={1}/>,
      },
       
    ]
    return patternsSubPages;
}
const Patterns = () => {
    const subPages=getPatternSubPages();
    const [formParams,setFormParams]=useState(false);
    return (
        <PageContainer header='Patterns'>
            <PageSlider pages={subPages}/>
            <Add route='patterns' setFormMethod={setFormParams}/>
            {formParams!==false?  <Form dbtype={formParams[0]} method={formParams[1]} setFormMethod={setFormParams}/> : <></>}
        </PageContainer>
    );
};

export default Patterns;