import {useState,useEffect } from 'react';
import dataAdapter from "../dataAdapter";
import {FileInput,Note} from './';
import {connect} from 'react-redux';
import {setDataReq,setByKey,setDataSuccess} from '../redux/actions/userInfoActions';
import api from '../api';
// UI COMPONENTS
import { TextField,FormControlLabel,InputLabel, Switch,FormControl,FormLabel,RadioGroup,Radio,Checkbox,Select,MenuItem} from '@mui/material';
import { LoadingButton } from '@mui/lab';


function objectToFormData(obj, fileKey) {
    const formData = new FormData();

    // Append all keys except the fileKey
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (key === fileKey) {
                // Skip the fileKey for now
                continue;
            }

            if (obj[key] instanceof File || obj[key] instanceof Blob) {
                // Handle files or blobs
                formData.append(key, obj[key]);
            } else if (Array.isArray(obj[key])) {
                // Handle arrays by appending each element with the same key
                obj[key].forEach((value) => formData.append(key, value));
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Convert nested objects to JSON strings
                formData.append(key, JSON.stringify(obj[key]));
            } else {
                // Add primitive values
                formData.append(key, obj[key]);
            }
        }
    }

    // Append the file key last
    if (fileKey && obj[fileKey]) {
        if (obj[fileKey] instanceof File || obj[fileKey] instanceof Blob) {
            // Append the file or blob
            formData.append(fileKey, obj[fileKey]);
        } else {
            // Handle cases where the fileKey exists but is not a File/Blob
            throw new Error(`The value of fileKey (${fileKey}) must be a File or Blob.`);
        }
    }

    return formData;
}
  
const ChosenForm=({dbtype,method,userInfo,submitForm,loader,getNearestDay,setFormState,userId,setError,updateStoreByRecordResponse})=>{

    // Initialize formData object by selected dbtype and method
    const [formData,setFormData]=useState({userId,...dataAdapter.formKeys(dbtype,method)});
    //   For Select Values
    const [selectedValue,setSelectedValue]=useState('');

    const handleSelectChange=(e)=>{
    setSelectedValue(e.target.value);
    handleFormInputByKey('_id',e.target.value);
    method==='PUT' && initializeFormData(e.target.value);
    }

    const initializeFormData=(id)=>{
        const indexOfItemToUpdate = userInfo[dbtype].findIndex(item => item._id === id);
        if(indexOfItemToUpdate!=-1 ){
            setFormData({...userInfo[dbtype][indexOfItemToUpdate],userId});
            console.log('selected doc');
            console.log(userInfo[dbtype][indexOfItemToUpdate]);
            // handle setting component that requires state and different data type
            // handleFormDataToState(userInfo[dbtype][indexOfItemToUpdate]);
        }
    }

    
    // handle update form state
    const handleFormInputByKey=(key,value)=>{
        const updateValue= value==='false' ? true : value==='true' ? false : value;
        console.log(value);
        // console.log(updateValue);
        // console.log(typeof updateValue);
        setFormData({...formData,[key]:updateValue})
        console.log(formData);
    }
    const handleFormInput=(e)=>{
        const updateValue= e.target.value==='false' ? true : e.target.value==='true' ? false : e.target.value;
        setFormData({...formData,[e.target.name]:updateValue})
    }
    const handleFormInputFile=(e)=>{
        console.log(e.target.name);
        console.log(e.target.files[0])
        setFormData({...formData,docData:e.target.files[0]})
    }
    useEffect(()=>{
        setFormData({...dataAdapter.formKeys(dbtype,method),userId});
    },[dbtype,method]);


  

    const chosenForm={
        pattern:{
            DELETE:()=>{
                return (
                <>
                    
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Pattern</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="Pattern"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                                {userInfo.pattern.map((currentPattern) => (
                                    <MenuItem key={currentPattern._id} value={currentPattern._id}>
                                        {currentPattern.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>delete</LoadingButton>
                        </FormControl>
                </>
                )
            },
            POST:()=>{
                return (
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="pattern name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Departments</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.departmentId}
                                label="Exchange Type"
                                name='departmentId'
                                onChange={handleFormInput}
                                style={{backgroundColor:'white'}}
                            >
                                    {userInfo['department'].map(dep=>
                                        <MenuItem key={dep._id} value={dep._id}>
                                            {dep.name}
                                        </MenuItem>
                                    )}
                            </Select>
                        </FormControl>
                
                        <FormControl>
                            <div className="input_label-wrapper"><label htmlFor="">Notes</label></div>
                            <div><Note formData={formData} setFormData={handleFormInputByKey}/></div>
                        </FormControl>
                        <FormControl>
                            <FileInput onChange={handleFormInputFile} text={formData.docData ? formData.docData.name : false}  value={formData.docData}/>
                        </FormControl>
                        
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>Add</LoadingButton>
                        </FormControl>
                    </>
                )
            },
            PUT:()=>{
                return (
                <>
                     <h1>Wokring On It...</h1>
                </>
                )
            }
        },
        user:{
        DELETE:()=>{
            return (
            <>
                
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Pattern</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Pattern"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.pattern.map((currentPattern) => (
                                <MenuItem key={currentPattern._id} value={currentPattern._id}>
                                    {currentPattern.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>delete</LoadingButton>
                    </FormControl>
            </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="firstName"
                            label="first name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        />
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="lastName"
                            label="last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={formData.username}
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            name="password"
                            label="password"
                            type="password"
                            id="password"
                            value={formData.password}
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        />
                    </FormControl>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.type}
                            label="User Type"
                            name='type'
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        >
                                <MenuItem value='analyst'>
                                    Analyst
                                </MenuItem>
                                <MenuItem value='investor'>
                                    Investor
                                </MenuItem>
                                <MenuItem value='programmer'>
                                    Programmer
                                </MenuItem>
                                
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Department</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.department}
                            label="Department"
                            name='department'
                            onChange={handleFormInput}
                            style={{backgroundColor:'white'}}
                        >
                                <MenuItem value='general'>
                                    General
                                </MenuItem>    
                        </Select>
                    </FormControl>
                    <FormControlLabel
                            label='Is Approve'
                            labelPlacement='top'
                            control={<Switch  value={formData.approved} checked={formData.approved} name='approved' onChange={handleFormInput}/>}
                        />
                    <FormControlLabel
                            label='Is Admin'
                            labelPlacement='top'
                            control={<Switch  value={formData.isAdmin} checked={formData.isAdmin} name='isAdmin' onChange={handleFormInput}/>}
                        />
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
            
            </>
            )
        }
        },
        department:{
        DELETE:()=>{
            return (
            <>
                
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Department</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            label="Pattern"
                            onChange={handleSelectChange}
                            style={{backgroundColor:'white'}}
                        >
                            {userInfo.department.map((currentPattern) => (
                                <MenuItem key={currentPattern._id} value={currentPattern._id}>
                                    {currentPattern.name}
                                </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>delete</LoadingButton>
                    </FormControl>
            </>
            )
        },
        POST:()=>{
            return (
                <>
                    <FormControl>
                        <TextField
                            margin="normal"
                            required
                            color="secondary"
                            fullWidth
                            id="name"
                            label="department name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInput}
                            sx={{
                                "& input": {
                                    bgcolor:'whitesmoke'
                                },
                                "& label": {
                                left: "unset",
                                right: "1.75rem",
                                transformOrigin: "right",
                                fontSize: "1rem",
                                "&.Mui-focused": {
                                    color: "black",
                                }
                                },
                                "& legend": {
                                textAlign: "right",
                                fontSize: "0.8rem",
                                },
                            }}
                            
                        />
                    </FormControl>
                    <FormControl>
                        <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>Add</LoadingButton>
                    </FormControl>
                </>
            )
        },
        PUT:()=>{
            return (
            <>
                 <h1>Wokring On It...</h1>
            </>
            )
        }
        },
        businessdoc:{
            DELETE:()=>{
                return (
                <>
                    
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">Business Doc</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedValue}
                                label="Business Doc"
                                onChange={handleSelectChange}
                                style={{backgroundColor:'white'}}
                            >
                                {userInfo.businessdoc.map((currentPattern) => (
                                    <MenuItem key={currentPattern._id} value={currentPattern._id}>
                                        {currentPattern.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>delete</LoadingButton>
                        </FormControl>
                </>
                )
            },
            POST:()=>{
                return (
                    <>
                        <FormControl>
                            <TextField
                                margin="normal"
                                required
                                color="secondary"
                                fullWidth
                                id="name"
                                label="business-doc name"
                                name="name"
                                value={formData.name}
                                onChange={handleFormInput}
                                sx={{
                                    "& input": {
                                        bgcolor:'whitesmoke'
                                    },
                                    "& label": {
                                    left: "unset",
                                    right: "1.75rem",
                                    transformOrigin: "right",
                                    fontSize: "1rem",
                                    "&.Mui-focused": {
                                        color: "black",
                                    }
                                    },
                                    "& legend": {
                                    textAlign: "right",
                                    fontSize: "0.8rem",
                                    },
                                }}
                                
                            />
                        </FormControl>
                        <FormControl>
                            <div className="input_label-wrapper"><label htmlFor="">Notes</label></div>
                            <div><Note formData={formData} setFormData={handleFormInputByKey}/></div>
                        </FormControl>
                        <FormControl>
                            <FileInput isPDF={true} onChange={handleFormInputFile} text={formData.docData ? formData.docData.name : false}  value={formData.docData}/>
                        </FormControl>
                        <FormControl>
                            <LoadingButton loading={loader} type='submit' variant="contained" color='secondary'>Add</LoadingButton>
                        </FormControl>
                    </>
                )
            },
            PUT:()=>{
                return (
                <>
                    <h1>Wokring On It...</h1>
                </>
                )
            }
        },
    }

    const handleSubmit=async (e)=>{
        try{
            e.preventDefault();
            let sentObj={...formData};
            if(formData.hasOwnProperty('docData') && formData.docData instanceof File){
                const mltPartForm=objectToFormData(sentObj,'docData');
                const newRecord=await api.postFile(dbtype,mltPartForm);
                if(newRecord.ok){
                    const recordData=await newRecord.json();
                    setFormState(false);
                    updateStoreByRecordResponse(dbtype,method,recordData);
                }
            }
            else{

            const unvalidKeys=dataAdapter.formValidation(dbtype,sentObj);

            unvalidKeys.length===0 ? submitForm(dbtype,method,sentObj) : setError();
            }
        }
        catch(err){
            console.log(err);
        }
    }
    
    return (
        <div dir='ltr'>
            <form  onSubmit={handleSubmit}>
                {chosenForm[dbtype][method]()}
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        loader:state.userInfo.loader,
        userId:state.authUser._id
      };
};
const mapDispatchToProps = (dispatch) => {
      return {
       submitForm:(dbtype,method,data)=>dispatch(setDataReq(dbtype,method,data)),
       setError:(message)=>dispatch(setByKey('isError',{status:true})),
       getNearestDay:(date,type) =>{
        console.log(type);
         // Clone the input date to avoid modifying the original object
         const clonedDate = new Date(date);
         console.log(clonedDate);
         // Get the current date
         const currentDate = new Date();

        switch (type){
            case 'weekly':
                // Calculate the difference in days between the current day and the target day
                const dayDifference = (clonedDate.getDay() - currentDate.getDay() + 7) % 7;
                // Clone the current date and set it to the nearest occurrence of the target day
                
                currentDate.setDate(currentDate.getDate() + dayDifference);
                break;
            case 'monthly':
                console.log(currentDate.getDate());
                console.log(clonedDate.getDate());
                currentDate.getDate()>clonedDate.getDate() && currentDate.setMonth(currentDate.getMonth()+1);
                currentDate.setDate(clonedDate.getDate());
                console.log(currentDate);
                break;
           
            case 'yearly':
                currentDate.getMonth()>clonedDate.getMonth() && currentDate.setFullYear(currentDate.getFullYear()+1);
                currentDate.setDate(clonedDate.getDate());
                currentDate.setMonth(clonedDate.getMonth());
                break;
               
        }
        
        return currentDate;
      },
      updateStoreByRecordResponse:(dbtype,method,data)=>dispatch(setDataSuccess(dbtype,method,data)),
      };
};

export default connect(mapStateToProps,mapDispatchToProps)(ChosenForm);