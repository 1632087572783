import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
const AddOptions=({route,setFormMethod,isOn})=>{
    let options;
    const off = {
        maxWidth: '0px',
        maxHeight: '0px',
        overflowX:'hidden',
        border: '0'
      };
    const on={
        maxWidth: '5000px',
        maxHeight: '5000px',
    }
    const setFormState=(formInfo)=>{
        setFormMethod(formInfo);
    }
   
    if(route==='patterns'){
        options = (
            <div style={isOn ? on :off} className="add-options-container">
               <div className="add-option" >
                <span className="add-option-header">
                    patterns
                </span>
                <span className="add-action-container">
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','POST'])} className="bi bi-plus-circle-fill"></i> */}
                            <AddCircleIcon onClick={()=>setFormState(['pattern','POST'])}/>
                        </span>
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','DELETE'])} className="bi bi-trash"></i> */}
                            <DeleteIcon onClick={()=>setFormState(['pattern','DELETE'])}/>
                        </span>
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','PUT'])} className="bi bi-pen"></i> */}
                            <EditIcon onClick={()=>setFormState(['pattern','PUT'])}/>
                        </span>
                    </span>
                </div>
            </div>
          );
    }
    if(route==='businessdoc'){
        options = (
            <div style={isOn ? on :off} className="add-options-container">
               <div className="add-option" >
                <span className="add-option-header">
                    business-doc
                </span>
                <span className="add-action-container">
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','POST'])} className="bi bi-plus-circle-fill"></i> */}
                            <AddCircleIcon onClick={()=>setFormState(['businessdoc','POST'])}/>
                        </span>
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','DELETE'])} className="bi bi-trash"></i> */}
                            <DeleteIcon onClick={()=>setFormState(['businessdoc','DELETE'])}/>
                        </span>
                        <span className="action">
                            {/* <i onClick={()=>setFormState(['patterns','PUT'])} className="bi bi-pen"></i> */}
                            <EditIcon onClick={()=>setFormState(['businessdoc','PUT'])}/>
                        </span>
                    </span>
                </div>
            </div>
          );
    }
  

    return (
    <>
    {options}
    </>
    )
}

export default AddOptions;