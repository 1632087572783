import  PageContainer  from '.././components/PageContainer';
import { connect } from "react-redux";
const MonitoredPatterns = () => {
    return (
        <PageContainer header={'Monitored Patterns'}>
            
        </PageContainer>
    );
};

export default MonitoredPatterns;