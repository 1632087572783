import {call,put,takeLatest,all} from "redux-saga/effects";
import api from "../../api";
import { loginSuccess,loginFailure,isAuthSuccess,isAuthFailure,registerSuccess,registerFailure,logoutSuccess,logoutFailure } from "../actions/authActions";
import {fetchUserInfo} from '../actions/userInfoActions';
import {IS_AUTH_REQUEST,LOGIN_REQUEST,REGISTER_REQUEST,LOGOUT_REQUEST} from '../types/authTypes';



function* loginSaga({payload}) {
    try {
      // Make an API call to log in
      
      const response = yield call(api.post, 'auth','login',payload);
      if(response.ok){
        const authUser=yield response.json();
        yield put(loginSuccess(authUser));
        yield put(fetchUserInfo())
      }
      else{

        const error= yield response.text();
        yield put(loginFailure(error));
      }
    } catch (error) {
      yield put(loginFailure(error));
    }
}

function* isAuthSaga(){
  try{
    
    const response=yield call(api.get,'auth','isAuth');
    const authUser=yield response.json();
    if(response.ok){
      yield put(isAuthSuccess(authUser));
      yield put(fetchUserInfo())
    }
    else{

      const error= yield response.text();
      yield put(isAuthFailure(error));
    }

  }
  catch(err){
    yield put(isAuthFailure(err));
  }
}

function* registerSaga({payload}){
  try{
    const response=yield call(api.post,'user','newUser',payload);
    if(response.ok){
      yield put(registerSuccess())
    }
    else{

      const error= yield response.text();
      yield put(registerFailure(error));
    }
  }
  catch(err){
    yield put(registerFailure(err));
  }
}

function* logoutSaga(){
  try{
    yield call(api.logout);
    yield put(logoutSuccess())
  }
  catch(err){
    yield put(logoutFailure());
  }
}

function* watchLogin(){
    yield takeLatest(LOGIN_REQUEST,loginSaga);
}

function* watchIsAuthSaga(){
  yield takeLatest(IS_AUTH_REQUEST,isAuthSaga)
}

function* watchRegister(){
  yield takeLatest(REGISTER_REQUEST,registerSaga)
}

function* watchLogout(){
  yield takeLatest(LOGOUT_REQUEST,logoutSaga)
}

export default function* authSaga(){
    yield all([watchLogin(),watchIsAuthSaga(),watchRegister(),watchLogout()])
}




