import React from 'react';
import NavHeader from "./NavHeader";
import NotificationBadge from './NotificationBadge';
const Header = () => {
    return (
        <div className='header'>
            <NavHeader/>
            <NotificationBadge/>
        </div>
    );
};

export default Header;