import {Window,ViewDoc} from './';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Button from '@mui/material/Button';

const Pattern = ({pattern}) => {
 
    return (
        <Window header={pattern.name} classes={`pattern-container container-boxShadow`}>
            <div className='flex_col_start'>
                <span>{`Stock Exchange Name : ${pattern.name}`}</span>
                <p>{`Notes : ${pattern.notes}`}</p>
            </div>
            <div className='flex_row_mid'>
                <ViewDoc dbtype={'pattern'} docPath={`${pattern._id}${pattern.docExt}`}/>
                <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        disabled={pattern.status==-1}
                        startIcon={<QueryStatsIcon />}
                        style={{backgroundColor:'white'}}
                    >
                        View Statistics
                </Button>
            </div>
        </Window>
    );
};

export default Pattern;