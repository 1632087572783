// import { connect } from "react-redux";

function Loader({}){

    return (
        <div className="loader">
        <div className="spinner"></div>
        <div className="text">Loading</div>
      </div>
    )
}
const mapStateToProps = (state) => {
  return {
    
  };
};
export default Loader;
// export default connect(mapStateToProps)(Loader);