import React from 'react';
import Form from '../Form';
const AddUser = ({classes,style}) => {
    return (
        <div style={style ? style : {}} className={`${classes}`}>
            <Form dbtype={'user'} method={'POST'} setFormMethod={true}/>
        </div>
    );
};

export default AddUser;