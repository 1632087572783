import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { connect } from "react-redux";
import Loader  from "./components/Loader";
import  Header from "./components/Header";
import {HomePage,Patterns,AdminPage,Login,MonitoredPatterns,BussinessExp} from './pages';
import {isAuthRequest} from "./redux/actions/authActions";

const theme = createTheme({
  palette: {
    default:{
      main:'#FFFFFF',
    },
    primary: {
      main: '#FFFFFF', // Change the primary color
    },
    secondary: {
      main: '#FFFFFF', // Change the secondary color
    },
    // direction: "rtl"
  },
});
function App({isAuth,loader,isAuthValidation,userInfo}) {
  useEffect(()=>{
    !isAuth && isAuthValidation();
  },[])
   
  return (
    <div className="App">
      <Router>
        { !isAuth ?
            loader ? <Loader/> : <Login/>
            :
            <ThemeProvider theme={theme}>
            <Header/>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/pattern" element={<Patterns />} />
              <Route exact path="/monitor" element={<MonitoredPatterns />} />
              <Route exact path="/business" element={<BussinessExp />} />
              <Route exact path="/admin" element={<AdminPage />} />
            </Routes>
            </ThemeProvider>
        }
      </Router>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuth:state.authUser.isAuth,
    loader:state.authUser.loader,
    userInfo:state.userInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    isAuthValidation: () => dispatch(isAuthRequest()),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(App);

