import {Window,ViewDoc} from '../';

const BusinessDoc = ({businessDoc}) => {
    return (
        <div>
        <Window header={businessDoc.name} classes={`pattern-container container-boxShadow`}>
            <div className='flex_col_start'>
                <span>{`Stock Exchange Name : ${businessDoc.name}`}</span>
                <p>{`Notes : ${businessDoc?.notes}`}</p>
            </div>
            <div className='flex_row_mid'>
                <ViewDoc dbtype={'businessdoc'} docPath={`${businessDoc._id}.pdf`}/>
            </div>
        </Window>
        </div>
    );
};

export default BusinessDoc;