import { useEffect } from "react";
import ChosenFrom from "./ChosenFrom";

const getHebrew=(dbtype,method)=>{
    let headline='';
    switch (method) {
        case "POST":
            headline += "Add-";
            break;
        case "DELETE":
            headline += "Delete-";
            break;
        case "PUT":
            headline += "Update-";
            break;
        case "UPDATE":
            headline += "Update-";
            break;
        default:
            return false;
    }
    switch (dbtype) {
        case "pattern":
          headline += "Pattern";
          break;

        case "user":
          headline += "User";
          break;
        case "department":
          headline += "Department";
          break;
        case "businessdoc":
          headline += "Business Doc";
          break;

        default:
          return false;
      }
      
   
    return headline;
}

const Form= ({dbtype,method,setFormMethod,isError,resetErrorState})=>{
    let headline=getHebrew(dbtype,method);
    const setFormState=(exitFormState,e)=>{
        document.getElementsByClassName('App')[0].removeAttribute('style');
        (e.target.className==='blurrBg' || e.target.className==='exit-form') && setFormMethod(exitFormState);
    }

    
    useEffect(()=>{
       document.getElementsByClassName('App')[0].style.cssText='overflow-y:hidden;';
    },[dbtype,method]);
    
    
    return (
     <>
        {method && typeof setFormMethod === 'function'  ?
            <div onClick={(e)=>setFormState(false,e)} className="blurrBg">
                <div className="form-container container-boxShadow_light">
                    <div className="txt_center">
                      <h2>{headline}</h2>
                      <span onClick={(e)=>setFormState(false,e)} className="exit-form">X</span>
                    </div>
                    <ChosenFrom dbtype={dbtype} method={method} setFormState={setFormMethod}/>
                </div>
            </div> 
            :

                <div style={{width:'100%'}} className="form-container container-boxShadow_light">
                    <div className="txt_center">
                      <h2>{headline}</h2>
                    </div>
                    <ChosenFrom dbtype={dbtype} method={method} setFormState={setFormMethod}/>
                </div>

        }
    </>
    )
}

export default Form;

