import {call,put,takeLatest,all, takeEvery} from "redux-saga/effects";
import api from "../../api";
import {fetchDataSuccess,fetchDataError,
  fetchUserInfoSuccess,fetchUserInfoFailure
  ,setDataSuccess,setDataFailure} from '../actions/userInfoActions';
import {FETCH_DATA_REQUEST,FETCH_USER_INFO,SET_DATA_REQUEST} from "../types/userInfoTypes"





 function* fetchUserInfo_Saga(){
  try{
    const response=yield call(api.getUserInfo);
    const userInfo=yield response.json();
    yield put(fetchUserInfoSuccess(userInfo))
    
  }
  catch(err){
    yield put(fetchUserInfoFailure(`get user info - Internet Error`))
    
  }
}

 function* fetchAllData_Saga({dbtype}){
    try{
        const response=yield call(api.getAll,dbtype);
        switch (response.status){
            case 200:
                const data=yield response.json();
                yield put(fetchDataSuccess(dbtype,data));
                break;
            case 500:
                yield put(fetchDataError(`${dbtype} - Server Error`));
                break;
            default:
                yield put(fetchDataError('Req Status !200 && !500 '))
                break;
        }
    }
    catch(err){
        put(fetchDataError(`${dbtype} - Internet Error`));
    }

}


  
function* setData_Saga({dbtype,method,data}){
  try{
    if(dbtype==='user'&&method==='POST'){
      const response=yield call(api.postPath,'user/new-user',data);
      if(response && response.ok){
        yield put(setDataSuccess());
      }
      else{
        yield put(setDataFailure());
      }
      
    }
    else{
      const response=yield call(api.setData,dbtype,method,data);
      if(response && response.ok){
        
        const retunredData=yield response.json();
        yield put(setDataSuccess(dbtype,method,retunredData));
        
      }
      else{
        yield put(setDataFailure());
        
      }
      
    }
  }
  catch(err){
    console.log(err);
    yield put(setDataFailure());
    
  }

}

function* watchFetchUserInfo(){
  yield takeLatest(FETCH_USER_INFO,fetchUserInfo_Saga)
}



function* watchFetchData(){
  yield takeLatest(FETCH_DATA_REQUEST,fetchAllData_Saga);
}



function* watchSetData(){
  yield takeEvery(SET_DATA_REQUEST,setData_Saga)
}

export default function* userInfoSaga(){
  yield all([
    watchFetchUserInfo(),
    watchFetchData(),
    watchSetData(),
  ])

}