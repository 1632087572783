import BusinessDoc from './BusinessDoc';

const BusinessDocContainer = ({businessDocs}) => {
    
    return (
        <div className='grid_3c'>
            {businessDocs.map((busDoc,index)=>
                <BusinessDoc key={index} businessDoc={busDoc}/>
            )}
        </div>
    );
};



  

  
  export default BusinessDocContainer;